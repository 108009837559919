<div class="expandable-card" [ngClass]="{ 'card card-no-border card-no-shadow': showAsCard }"
     [class.expanded]="isExpanded" [class.disabled]="disabled">
    <div [class.card-header]="showAsCard">
        <div class="expandable-card-header">
            @if (!toggleButton && showToggleButton) {
                <div class="d-flex align-items-center">
                    <ng-template [ngTemplateOutlet]="headerTpl"></ng-template>
                </div>
            } @else {
                <ng-template [ngTemplateOutlet]="header?.templateRef"></ng-template>
            }
        </div>
    </div>
    @if (isExpanded) {
        <div class="expandable-card-content">
            <div [class.card-body]="showAsCard">
                <ng-template [ngTemplateOutlet]="content?.templateRef"></ng-template>
            </div>
        </div>
    }
</div>

<ng-template #headerTpl>
    @if (showToggleButton) {
        <ng-container [ngTemplateOutlet]="defaultToggleButtonTpl"></ng-container>
    }
    <ng-template [ngTemplateOutlet]="header?.templateRef"></ng-template>
</ng-template>

<ng-template #defaultToggleButtonTpl>
    <button (click)="toggle()" type="button" class="btn btn-fa me-2 expandable-card-toggle-button"
            [class.expanded]="isExpanded">
        <fa-icon [icon]="['fal', 'angle-down']" size="2x"></fa-icon>
    </button>
</ng-template>
