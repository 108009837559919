import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CableCategoryService } from '@obo-common/shared/services/cableCategory.service';
import { FileSavingService } from '@obo-common/shared/services/fileSaving.service';
import { FlaggedEnumService } from '@obo-common/shared/services/flaggedEnum.service';
import { PrintService } from '@obo-common/shared/services/print.service';
import { BreakpointService } from './services/breakpoint.service';
import { GuestExportService } from './services/guestExport.service';
import { ToggleService } from './services/toggle.service';
import { FileSaverModule } from 'ngx-filesaver';

@NgModule({
    imports: [CommonModule, FileSaverModule],
    providers: [
        CableCategoryService,
        GuestExportService,
        BreakpointService,
        FileSavingService,
        PrintService,
        FlaggedEnumService,
        ToggleService
    ],
    declarations: []
})
export class SharedServicesModule {}
