<section id="project">
    <div class="project-grid">
        <div class="go-to-dashboard">
            <a uiSref="dashboard">
                <fa-icon [icon]="['fal', 'arrow-left']" class="me-2"></fa-icon>
                {{ 'GO_TO_DASHBOARD' | translate }}
            </a>
        </div>
        <div class="project-detail">
            <app-project-detail [project]="project"></app-project-detail>
        </div>
        <div class="buildings mt-3">
            @if (buildings.length > 0) {
            <div class="building-search">
                <input
                    id="building-search"
                    class="form-control"
                    [attr.placeholder]="'SHARED_SEARCH_DOTS' | translate"
                    (input)="filterBuildings($event.target.value)" />
                <span class="icon">
                    <fa-icon [icon]="['fal', 'magnifying-glass']"></fa-icon>
                </span>
            </div>
            <div class="building-list hide-scrollbar">
                <div class="add-building-card" (click)="addBuilding()">
                    <fa-icon [icon]="['fal', 'plus']"></fa-icon>
                    <span class="d-none d-md-block">{{ 'BUILDING_NEW' | translate }}</span>
                </div>
                @for (building of filteredBuildings; track building.id) {
                <app-building-card
                    [building]="building"
                    [projectId]="project.id"
                    [regions]="regions"
                    [selected]="selectedBuilding.id === building.id"
                    (selectedChange)="selectedBuilding = building"
                    (buildingChange)="reloadBuildings($event)"></app-building-card>
                }
            </div>
            }
        </div>
        <div class="plannings">
            @if (selectedBuilding) {
            <app-plannings
                [plannings]="selectedBuilding.plannings"
                [buildingId]="selectedBuilding.id"
                [projectId]="project.id"
                [isMobile]="isMobile"
                (reloadBuildings)="reloadBuildings($event)"></app-plannings>
            } @else {
            <div class="empty-state">
                <h2 class="m-0">
                    {{ 'PROJECT_NO_BUILDING_YET' | translate }}
                </h2>
                <a (click)="addBuilding()" class="add-building-link">{{ 'PROJECT_ADD_BUILDING_LINK' | translate }}</a>
                <fa-icon [icon]="['fal', 'house-building']" size="4x"></fa-icon>
            </div>
            }
        </div>
        <input
            #planningImport
            class="d-none"
            accept="application/json"
            name="planningImport"
            id="planningImport"
            type="file"
            (change)="checkIfPlanningIsImportable($event)" />
    </div>
    @if (selectedBuilding) {
    <shd-floating-action-button
        [faPrefix]="'fal'"
        [faIcon]="'plus'"
        [label]="'BUILDING_PLANNING_IMPORT' | translate"
        [spaceBetweenButtons]="63">
        @for (planning of selectedBuilding.plannings; track planning.id) { @if (moduleAllowsImportExport(planning.moduleId)) {
        <shd-floating-action-button-item
            [content]="'BUILDING_MODULES_' + planning.name | uppercase | translate"
            (clicked)="planningToImport = planning.name; planningImport.click()">
            <ng-template shdFloatingActionButtonItemContent>
                <div class="d-flex">
                    <span class="text-white me-2">
                        {{ 'BUILDING_MODULES_' + planning.name | uppercase | translate }}
                    </span>
                    <shd-module-icon placement="left" [moduleName]="planning.name" [color]="'white'"></shd-module-icon>
                </div>
            </ng-template>
        </shd-floating-action-button-item>
        } }
    </shd-floating-action-button>
    }
</section>

<shd-confirm-modal
    [title]="'BUILDING_PLANNING_IMPORT' | translate"
    (confirmed)="createBuildingAndImportPlanning()">
    <ng-template shdConfirmModalQuestion>
        <shd-infobox [headerText]="'BUILDING_PLANNING_IMPORT_HINT' | translate"></shd-infobox>
        <p class="mt-4">
            {{
                'BUILDING_PLANNING_IMPORT_QUESTION'
                    | translate
                    | stringFormat : [selectedBuilding.name, 'BUILDING_MODULES_' + planningToImport | uppercase | translate]
            }}
        </p>
    </ng-template>
</shd-confirm-modal>
